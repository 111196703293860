<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="姓名">
          <a-input
            v-model:value="params.name"
            placeholder="输入姓名"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input
            v-model:value="params.phone"
            placeholder="输入联系方式"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="车场">
          <a-select
            v-model:value="params.parkingLotId"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            @change="getParkingPlace"
          >
            <a-select-option
              v-for="item in parkingLotList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="btn-success" @click="showAddModal">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            添加
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ y: 500 }"
          bordered
        >
          <template #state="{ text }">
            <a-tag :color="text == 1 ? 'green' : 'red'">
              {{ text == 1 ? "在职" : "离职" }}
            </a-tag>
          </template>
          <template #operation="{ record }">
            <div>
              <TipButton
                perm="maintenance:pdaUser:edit"
                btn-class="btn-success"
                title="编辑"
                @btnClick="showEditModal(record.id)"
              >
                <template #default>
                  <EditOutlined />
                </template>
              </TipButton>
              <TipButton
                perm="maintenance:pdaUser:del"
                btn-class="btn-danger"
                title="删除"
                @btnClick="delItem(record.id)"
              >
                <template #default>
                  <DeleteOutlined />
                </template>
              </TipButton>
            </div>
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <add
    v-if="addShow"
    :modal-ref="$refs.modalContainer"
    @close="hideAddModal"
  ></add>
  <edit
    v-if="editShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideEditModal"
  ></edit>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted } from "vue";
import Add from "@/views/Maintenance/PdaUser/components/Add";
import Edit from "@/views/Maintenance/PdaUser/components/Edit";
import useMessage from "@/hooks/useMessage";
import TipButton from "@/components/TipButton";
import { deletePdaUser } from "@/api/maintenance/pdaUserApi";
import usePdaUser from "../../../hooks/maintenance/usePdaUser";

export default {
  name: "index",
  components: {
    Edit,
    Add,
    CardHeader,
    TipButton,
  },
  setup() {
    const {
      params,
      dataList,
      total,
      loading,
      columns,
      parkingLotList,
      loadData,
      getParkingLotList,
    } = usePdaUser();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);

    const {
      currentId,
      addShow,
      editShow,
      showAddModal,
      showEditModal,
      hideAddModal,
      hideEditModal,
    } = useAddExit(doQuery);

    const { showConfirm, showLoading, closeLoading, showTipMessage, showErr } =
      useMessage();

    onMounted(async () => {
      getParkingLotList();
      loadData(page.value, pageSize.value);
    });

    const delItem = async id => {
      let action = await showConfirm("是否删除巡检员?");
      if (action) {
        await showLoading("正在删除，请稍后...");
        try {
          await deletePdaUser(id);
          await closeLoading();
          await showTipMessage("删除成功", "success");
          doQuery();
        } catch (e) {
          await closeLoading();
          await showErr(e);
        }
      }
    };

    return {
      parkingLotList,
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,

      currentId,
      addShow,
      editShow,
      showEditModal,
      showAddModal,
      hideAddModal,
      hideEditModal,
      delItem,
    };
  },
};
</script>

<style scoped></style>
